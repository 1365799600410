<template>
  <div class="home-page"
       v-bind:class="isFullScreen?'isFull':''">
    <div class="dp-box">
      <map-chart class="bmap-content"
                 :datas="selectVal"></map-chart>
      <!-- 顶层内容 -->
      <div class="dp-box-content">
        <div class="top-title top-title-font">
          <div v-if="selectVal.shard"
               class="top-title-3d"><span class="shard-name">{{selectVal.shard}}</span> 分片数据总览</div>
          <div v-else
               class="top-title-3d">全部分片数据总览</div>
        </div>
        <el-select class="select select-1"
                   @change="handleSelect"
                   :popper-append-to-body="false"
                   v-model="selectVal.shard"
                   placeholder="请选择">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <!-- <el-select class="select select-2"
                   @change="handleType"
                   :popper-append-to-body="false"
                   v-model="selectVal.state"
                   placeholder="请选择">
          <el-option v-for="item in stateOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select> -->
        <!-- 全屏按钮 -->
        <div class="full-box"
             v-if="!isFullScreen"
             @click="fullScreen()">
          <img class="full"
               src="./img/full.png">
        </div>
        <div class="full-box"
             v-else-if="isFullScreen"
             @click="exitScreen()">
          <img class="full"
               src="./img/full.png">
        </div>
        <!-- 左侧内容 -->
        <div class="left-content">
          <div class="left-content-top">
            <div class="left-title">
              <img class="left-title-img"
                   src="./img/left-title.png">
              <span class="box-title">打包账本数</span>
            </div>
            <!-- 账本折线图 -->
            <ledger-chart class="ledger-chart"
                          :datas="blockLine"></ledger-chart>
          </div>
          <div class="left-content-bottom">
            <div class="left-title">
              <img class="left-title-img"
                   src="./img/left-title.png">
              <span class="box-title">出块统计</span>
            </div>
            <!-- 折线图 -->
            <block-chart class="ledger-chart"
                         :datas="ledgerLine"></block-chart>
          </div>
        </div>

        <!-- 中间内容 -->
        <div class="middle-content">
          <div class="long-width-max-div">
            <div class="middle-content-little-box-1">
              <div class="little-box-title shenglue">区块统计</div>
              <div class="little-box-number shenglue">{{getNumber(heightMax)}}</div>
            </div>
            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">账本统计</div>
              <div class="little-box-number shenglue">{{getNumber(difficultyCount)}}</div>
            </div>
            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">合约统计</div>
              <div class="little-box-number shenglue">{{getNumber(contractCount)}}</div>
            </div>

            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">账户统计</div>
              <div class="little-box-number shenglue">{{getNumber(accountAlgorithmCount)}}</div>
            </div>
            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">节点统计</div>
              <div class="little-box-number shenglue">{{getNumber(total)}}</div>
            </div>
            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">可编程模板</div>
              <div class="little-box-number shenglue">{{getNumber(tempCount)}}</div>
            </div>
            <div class="middle-content-little-box">
              <div class="little-box-title shenglue">可编程账本</div>
              <div class="little-box-number shenglue">{{getNumber(futureCount)}}</div>
            </div>
          </div>
        </div>

        <!-- 右侧内容 -->
        <div class="right-content">
          <div class="right-content-box">
            <div class="left-title">
              <img class="left-title-img"
                   src="./img/left-title.png">
              <span class="box-title">最新区块信息</span>
            </div>
            <!-- 列表 -->
            <div class="table-wrapper">
              <el-table v-if="blockTable.length !== 0"
                        :data="blockTable"
                        :max-height="tableHeight"
                        :header-cell-style="tableHeaderColor"
                        style="width: 98%">
                <el-table-column prop="height"
                                 label="区块高度">
                </el-table-column>
                <!-- <el-table-column prop="shardName"
                                 label="分片名"
                                 min-width="60px">
                </el-table-column> -->
                <el-table-column prop="size"
                                 label="账本数"
                                 >
                </el-table-column>
                <el-table-column prop="date"
                                 label="打包时间"
                                 min-width="100px">
                </el-table-column>
              </el-table>
              <div class="none-div"
                   v-else>暂无数据</div>
            </div>
          </div>
        </div>

        <!-- 底部内容 -->
        <div class="bottom-content"
             :class="this.isFullScreen?'bottom-height-3':'bottom-height-3'">
          <div class="bottom-content-box">
            <div class="left-title">
              <img class="left-title-img"
                   src="./img/left-title.png">
              <span class="box-title">最新账本信息</span>
            </div>
            <!-- 列表 -->
            <div class="table-wrapper-1">
              <el-table v-if="ledgerTable.length !== 0"
                        :data="ledgerTable"
                        :max-height="tableHeight1"
                        :header-cell-style="tableHeaderColor"
                        :row-class-name="tabRowClassName"
                        style="width: 96%">
                <el-table-column prop="ledgerCodeName"
                                 label="账本类型"
                                 min-width="100px">
                </el-table-column>
                <el-table-column prop="hash"
                                 label="账本哈希"
                                 min-width="400px">
                  <template slot-scope="scope">
                    <div class="shenglue">
                      {{scope.row.hash}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="result"
                                 label="执行结果"
                                 min-width="60px">
                  <template slot-scope="scope">

                    <div class="row-status"
                         v-if="scope.row.result">
                      <span class="status-success"></span>
                      <span style="font-size:14px">成功</span>
                    </div>
                    <div class="row-status"
                         v-else-if="!scope.row.result">
                      <span class="status-fail dot"></span>
                      <span style="font-size:14px">失败</span>
                    </div>

                  </template>
                </el-table-column>
                <el-table-column prop="time"
                                 label="验证时间"
                                 min-width="100px">
                </el-table-column>
              </el-table>
              <div class="none-div"
                   v-else>暂无数据</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import MapChart from './component/map-chart.vue'
import LedgerChart from './component/ledger-line-chart.vue'
import BlockChart from './component/block-line-chart.vue'
import format from '../../../../utils/timeFormat.js'
import {
  setShardName,
  getShardName,
  // setNetworkType,
  // getNetworkType,
  setRefresh,
  getRefresh
} from '../../../../utils/localStorage.js'
export default {
  components: { MapChart, LedgerChart, BlockChart },
  data() {
    return {
      bottomHeight: false, // 动态底部表格蒙层高度
      isFullScreen:
        document.isFullScreen ||
        document.mozIsFullScreen ||
        document.webkitIsFullScreen,
      timer: null, // 定时器
      tableHeight: 0, // 表格自适应高度
      tableHeight1: 0, // 表格自适应高度
      heightMax: 0, // 最新区块高度
      difficultyCount: 0, // 实际账本数量
      contractCount: 0, // 合约数量
      futureCount: 0, // 可编程账本统计
      tempCount: 0, // 可编程模板统计
      accountAlgorithmCount: 0, // 账户数量
      total: 0, // 节点数量
      options: [],
      // stateOpt: [
      //   {
      //     value: 'main',
      //     label: '主网'
      //   },
      //   {
      //     value: 'test',
      //     label: '测试网络'
      //   },
      //   {
      //     value: 'dev',
      //     label: '开发网络'
      //   }
      // ],
      selectVal: {
        shard: null,
        // state: 'main',
        total: 0
      },
      blockTable: [],
      ledgerTable: [],
      blockLine: {
        x: [],
        y1: [],
        y2: [],
        y3: []
      },
      ledgerLine: {
        x: [],
        y: []
      }
    }
  },
  computed: {},
  created() {
    // 动态获取屏幕高度(独立项目的显示)
    // this.tableHeight =
    //   window.innerHeight * 0.59 > 440 ? window.innerHeight * 0.59 : 440
    // this.tableHeight1 =
    //   window.innerHeight * 0.24 - 60 > 130
    //     ? window.innerHeight * 0.24 - 60
    //     : 130
    // 动态获取屏幕高度(非独立)
    this.tableHeight =
      window.innerHeight * 0.53 > 440 ? window.innerHeight * 0.53 : 440
    this.tableHeight1 =
      window.innerHeight * 0.25 - 90 > 130
        ? window.innerHeight * 0.25 - 90
        : 130

    if (getShardName()) {
      this.selectVal.shard = getShardName()
    }
    // else {
    //   setTimeout(() => {
    //     this.selectVal.shard = this.options[0].value
    //     setShardName(this.options[0].value)
    //   }, 2000)
    // }
    // if (getNetworkType()) {
    //   this.selectVal.state = getNetworkType()
    // } else {
    //   setNetworkType(this.selectVal.state)
    // }
  },
  mounted() {
    // console.log('num:', this.getNumber(104010000))
    // 动态获取
    var _this = this
    window.onresize = function () {
      _this.fetTableHeight()
    }

    this.newBlockList()
    this.newLedgerList()
    this.topInfo()
    this.getAliases()
    this.getblockLine()
    this.getLedgerLine()
    setRefresh(true)
    // 每3秒刷新
    this.refresh(15000)
    // 新手指引
    this.$nextTick(() => {
      this.$publicFunction.myIntroJs(this.$route.name)
    })
  },
  beforeDestroy() {
    if (this.timer) {
      // console.log('清除定时器1')
      window.clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 数量单位处理
    getNumber(value) {
      if (value >= 1e4 && value < 1e8) {
        let num = Math.round(value / 100) / 100
        return num + ' 万'
      } else if (value >= 1e8) {
        let num = Math.round(value / 1e6) / 100
        return num + ' 亿'
      } else {
        return value
      }
    },
    // 实时刷新
    refresh(time) {
      var _this = this
      window.clearInterval(this.timer)
      this.timer = window.setInterval(function () {
        if (getRefresh()) {
          _this.newBlockList()
          _this.newLedgerList()
          _this.topInfo()
          _this.getblockLine()
          _this.getLedgerLine()
        }
      }, time)
    },
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeight = 0
        this.tableHeight1 = 0
        resolve()
      })
    },
    // 设置table高度(独立项目的显示)
    // fetTableHeight() {
    //   this.resetHeight().then((res) => {
    //     this.tableHeight1 =
    //       window.innerHeight * 0.24 - 60 > 130
    //         ? window.innerHeight * 0.24 - 60
    //         : 130
    //     if (this.isFullScreen) {
    //       this.tableHeight = window.innerHeight * 0.6
    //     } else {
    //       this.tableHeight =
    //         window.innerHeight * 0.59 > 440 ? window.innerHeight * 0.59 : 440
    //     }
    //     // console.log('tableHeight1:', this.tableHeight1)
    //   })
    // },
    // 设置table高度(非独立)
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeight1 =
          window.innerHeight * 0.25 - 90 > 130
            ? window.innerHeight * 0.25 - 90
            : 130
        if (this.isFullScreen) {
          this.tableHeight = window.innerHeight * 0.6
        } else {
          this.tableHeight =
            window.innerHeight * 0.53 > 440 ? window.innerHeight * 0.53 : 440
        }
        // console.log('tableHeight1:', this.tableHeight1)
      })
    },
    // 进入全屏
    fullScreen() {
      let main = document.documentElement
      if (main.requestFullscreen) {
        main.requestFullscreen()
      } else if (main.mozRequestFullScreen) {
        main.mozRequestFullScreen()
      } else if (main.webkitRequestFullScreen) {
        main.webkitRequestFullScreen()
      } else if (main.msRequestFullscreen) {
        main.msRequestFullscreen()
      }
      this.isFullScreen = true
      this.bottomHeight = true
    },
    // 退出全屏
    exitScreen() {
      let main = document
      if (main.exitFullscreen) {
        main.exitFullscreen()
      } else if (main.mozCancelFullScreen) {
        main.mozCancelFullScreen()
      } else if (main.webkitCancelFullScreen) {
        main.webkitCancelFullScreen()
      } else if (main.msExitFullscreen) {
        main.msExitFullscreen()
      }
      this.isFullScreen = false
      this.bottomHeight = false
    },
    // 网络类型切换刷新
    // handleType() {
    //   // console.log('select:', this.selectVal.state)
    //   setNetworkType(this.selectVal.state)
    //   this.listNode()
    // },
    // 分片名切换刷新
    handleSelect() {
      // console.log('select:', this.selectVal.shard)
      setShardName(this.selectVal.shard)
      this.newBlockList()
      this.newLedgerList()
      this.topInfo()
      // this.getAliases()
      this.getblockLine()
      this.getLedgerLine()
    },
    // 左侧上折线图
    async getblockLine() {
      let { data } = await this.$api.chainQL.blockLine(this.selectVal.shard)
      // console.log(data.listBlock.block)
      this.blockLine = {
        x: [],
        y1: [],
        y2: [],
        y3: []
      }
      if (data && data.listBlock) {
        let list = data.listBlock.block
        for (var i = 5; i >= 0; i--) {
          // 区块高度
          this.blockLine.x.push(list[i].height)
          // 实际打包账本数
          this.blockLine.y1.push(list[i].size)
          // 打包账本目标数
          this.blockLine.y2.push(list[i].difficulty)
          // 出块时间
          this.blockLine.y3.push(list[i].timestamp - list[i + 1].timestamp)
        }
        // console.log(this.blockLine)
      }
    },
    // 左侧下折线图
    async getLedgerLine() {
      let { data } = await this.$api.chainQL.blockChart(this.selectVal.shard)
      // console.log(data)
      this.ledgerLine = {
        x: [],
        y: []
      }
      if (data && data.listBlock) {
        let list = data.listBlock.timestampTerms
        for (var item of list) {
          // 区块高度
          this.ledgerLine.x.push(format(item.key, 'MM-DD'))
          // 出块数量
          this.ledgerLine.y.push(item.value)
        }
      }
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      // console.log(data)
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    // 顶部信息
    topInfo() {
      this.blockAggregation()
      this.ledgerAggregation()
      this.accountAggregation()
      this.listNode()
      this.contractsDifficulty()
      this.futureDifficulty()
      this.tempDifficulty()
    },
    // 最新区块高度
    async blockAggregation() {
      // this.heightMax = 0
      let { data } = await this.$api.chainQL.heightMax(this.selectVal.shard)
      if (data && data.listBlock) {
        this.heightMax = data.listBlock.total
      } else {
        this.heightMax = 0
      }
    },
    // 实际账本数量
    async ledgerAggregation() {
      // this.difficultyCount = 0
      let { data } = await this.$api.chainQL.difficultyCount(
        this.selectVal.shard
      )
      if (data && data.listLedger) {
        this.difficultyCount = data.listLedger.total
      } else {
        this.difficultyCount = 0
      }
    },
    // 合约数量
    async contractsDifficulty() {
      // this.contractCount = 0
      let { data } = await this.$api.chainQL.contracts(this.selectVal.shard)
      // console.log(data)
      if (data && data.listLedger) {
        this.contractCount = data.listLedger.total
      } else {
        this.contractCount = 0
      }
    },
    // 可编程账本数量
    async futureDifficulty() {
      // this.futureCount = 0
      let { data } = await this.$api.chainQL.future(this.selectVal.shard)
      // console.log(data)
      if (data && data.listLedger) {
        this.futureCount = data.listLedger.total
      } else {
        this.futureCount = 0
      }
    },
    // 可编程模板数量
    async tempDifficulty() {
      // this.futureCount = 0
      let { data } = await this.$api.chainQL.temp(this.selectVal.shard)
      // console.log(data)
      if (data.listLedger) {
        this.tempCount = data.listLedger.total
      } else {
        this.tempCount = 0
      }
    },
    // 账户数量
    async accountAggregation() {
      // this.accountAlgorithmCount = 0
      let { data } = await this.$api.chainQL.accountAlgorithmCount(
        this.selectVal.shard
      )
      if (data && data.listAccount) {
        this.accountAlgorithmCount = data.listAccount.total
      } else {
        this.accountAlgorithmCount = 0
      }
    },
    // 节点数量
    async listNode() {
      // this.total = 0
      let options = {
        peerName: this.selectVal.shard,
        keepLive: true
        // networkType: this.selectVal.state
      }
      let { data } = await this.$api.home.getNodes(options)
      if (data && data.length !== 0) {
        this.total = data.length
        this.selectVal.total = data.length
      } else {
        this.total = 0
        this.selectVal.total = 0
      }
    },
    // 最新区块信息
    async newBlockList() {
      // 初始化
      // this.blockTable = []
      let { data } = await this.$api.chainQL.listBlock(this.selectVal.shard)
      // console.log(data.listBlock.block)
      if (data && data.listBlock) {
        let list = data.listBlock.block
        let array = []
        for (let item of list) {
          let obj = {}
          obj.date = format(item.timestamp, 'HH:mm:ss')
          obj.height = item.height
          // obj.difficulty = item.difficulty
          obj.shardName = item.shardName
          obj.size = item.size
          array.push(obj)
        }
        this.blockTable = array
        // console.log(this.blockTable)
      } else {
        this.blockTable = []
      }
    },
    // 最新账本信息
    async newLedgerList() {
      // 初始化
      // this.ledgerTable = []
      let { data } = await this.$api.chainQL.listLedger(this.selectVal.shard)
      // console.log(data.listLedger.ledger)
      if (data && data.listLedger) {
        let list = data.listLedger.ledger
        let array = []
        for (let item of list) {
          let obj = {}
          obj.time = format(item.timestamp, 'YYYY-MM-DD HH:mm:ss')
          obj.ledgerCodeName = item.ledgerCodeName
          obj.hash = item.hash
          obj.result = item.result
          array.push(obj)
        }
        this.ledgerTable = array
        // console.log(this.ledgerTable)
      } else {
        this.ledgerTable = []
      }
    },
    // 设置表头行的样式
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return 'background-color: transparent;'
    },
    // 设置行的样式
    tabRowClassName({ row, rowIndex }) {
      let index = rowIndex + 1
      if (index % 2 === 0) {
        return 'background:#F3F9FF'
      }
    }
  },
  filters: {
    priceFilter(val) {
      if (!val) {
        return
      }
      return parseFloat(val).toFixed(2)
    }
  }
}
</script>

<style lang='scss' scoped>
.isFull {
  position: absolute;
  z-index: 9999;
  width: 100%;
  // height: 96.1% !important;
  height: 97.1% !important;
  top: 0px;
  left: 0px;
}
.home-page {
  min-width: 1180px;
  min-height: 700px;
  height: calc(100% - 30px);
  .light {
    color: $--color-text-placeholder;
  }

  h2 {
    color: $--color-text-regular;
  }
  h3 {
    color: $--color-text-secondary;
  }
  h4 {
    color: $--color-text-placeholder;
  }
  h5 {
    color: $--color-success;
  }
  h6 {
    color: $--color-warning;
  }

  .dp-box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bmap-content {
    position: absolute;
    width: 100%;
    height: 103%;
    z-index: 99;
  }
  // 顶层内容
  .dp-box-content {
    position: relative;
    width: 100%;
    height: 100%;
    .top-title {
      position: absolute;
      width: 100%;
      height: 8%;
      z-index: 99;
      background: url(./img/top-title.png) no-repeat;
      background-size: 100% 100%;
    }
    .top-title-font {
      text-align: center;
      font-size: 30px;
      font-weight: 900;
      letter-spacing: 0.2rem;
      // font-variant: small-caps;
      // font-style: oblique;
      // color: #eb6b1d;
    }
    .shard-name {
      letter-spacing: 0.1rem;
    }
    .top-title-3d {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -60%);
      cursor: pointer;
      background-image: -webkit-linear-gradient(
        bottom,
        rgba(255, 255, 255, 0.6),
        #ffffff
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .select-2 {
      position: absolute;
      top: 1rem;
      right: 10.4rem;
      z-index: 1000;
      width: 140px;
    }
    .select-1 {
      position: absolute;
      top: 1rem;
      right: 0.6rem;
      z-index: 1000;
      width: 140px;
    }
    // 全屏按钮
    .full-box {
      position: absolute;
      top: 1rem;
      left: 0.6rem;
      z-index: 1000;
      width: 2rem;
      height: 2rem;
      background: url(./img/full-box.png) no-repeat;
      background-size: 100% 100%;
      .full {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.2rem;
        height: 1.2rem;
      }
      .full:hover {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
    // 左侧内容
    .left-content {
      position: absolute;
      top: 8.8%;
      left: 0.6rem;
      z-index: 99;
      width: 22.5%;
      height: 68%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // 顶部盒子折线图
      .left-content-top {
        background: url(./img/list-width.png) no-repeat;
        background-size: 100% 100%;
        height: 49.4%;
      }
      // 底部盒子折线图
      .left-content-bottom {
        background: url(./img/list-width.png) no-repeat;
        background-size: 100% 100%;
        height: 49.4%;
      }
      // 标题栏
      .left-title {
        display: flex;
        flex-direction: row;
        padding: 0.4rem 0rem;
        line-height: 2rem;
        .left-title-img {
          width: 0.5rem;
        }
        .box-title {
          color: #f5dcad;
          font-weight: bold;
          margin-left: 0.6rem;
        }
      }
    }
    // 中间内容
    .middle-content {
      position: absolute;
      top: 9.2%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      width: 52.8%;
      .long-width-max-div {
        background: url(./img/long-width-min.png) no-repeat;
        background-size: 100% 60%;
        height: 10rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .middle-content-little-box {
          display: flex;
          flex-direction: column;
          width: 14%;
          height: 60%;
          // background: olivedrab;
          .little-box-title {
            text-align: center;
            line-height: 3.4rem;
            color: #2ed8ff;
            font-size: 0.8rem;
            font-weight: bold;
          }
          .little-box-number {
            text-align: center;
            line-height: 1.6rem;
            color: #f5dcad;
            font-size: 2.1vh;
            font-weight: bold;
          }
        }
        .middle-content-little-box-1 {
          display: flex;
          flex-direction: column;
          width: 16%;
          height: 60%;
          // margin-left: 10px;
          // background: olivedrab;
          .little-box-title {
            text-align: center;
            line-height: 3.4rem;
            color: #2ed8ff;
            font-size: 0.8rem;
            font-weight: bold;
          }
          .little-box-number {
            text-align: center;
            line-height: 1.6rem;
            color: #f5dcad;
            font-size: 2.1vh;
            font-weight: bold;
          }
        }
      }
    }
    .none-div {
      color: #9ca0ab;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    // 右侧内容
    .right-content {
      position: absolute;
      top: 8.8%;
      right: 0.6rem;
      z-index: 99;
      width: 22.5%;
      height: 68%;
      // 右侧盒子列表
      .right-content-box {
        background: url(./img/list-height.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
      }
      // 标题栏
      .left-title {
        display: flex;
        flex-direction: row;
        padding: 0.4rem 0rem;
        line-height: 2rem;
        .left-title-img {
          width: 0.5rem;
        }
        .box-title {
          color: #f5dcad;
          font-weight: bold;
          margin-left: 0.6rem;
        }
      }
    }
    .bottom-height-1 {
      height: 24.6%;
      bottom: -1.4rem;
    }
    .bottom-height-2 {
      height: 24%;
      bottom: -1rem;
    }
    // 非独立时表格高度
    .bottom-height-3 {
      height: 23.6%;
      bottom: -0.8rem;
    }
    // 底部内容
    .bottom-content {
      position: absolute;
      left: 0.6rem;
      z-index: 99;
      width: calc(100% - 1.2rem);
      // height: 23.6%;
      // 底部盒子列表
      .bottom-content-box {
        background: url(./img/long-width-max.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
      }
      // 标题栏
      .left-title {
        display: flex;
        flex-direction: row;
        padding: 0.4rem 0rem;
        line-height: 2rem;
        .left-title-img {
          width: 0.5rem;
        }
        .box-title {
          color: #f5dcad;
          font-weight: bold;
          margin-left: 0.6rem;
        }
      }
      .row-status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        line-height: 1rem;
        color: #e1e0f5;
        .status-success {
          width: 0.8rem;
          height: 0.8rem;
          line-height: 1rem;
          border-radius: 100%;
          background: #2fc25b;
          margin-right: 0.6rem;
        }
        .status-fail {
          width: 0.8rem;
          height: 0.8rem;
          line-height: 1rem;
          border-radius: 100%;
          background: #c23531;
          margin-right: 0.6rem;
        }
      }
    }
    // 省略不换行
    .shenglue {
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
      color: #e1e0f5;
    }
    ::v-deep {
      // 设置表格透明
      .table-wrapper .el-table,
      .el-table__expanded-cell {
        margin-left: 2%;
        background-color: transparent;
        position: absolute;
        top: 6%;
      }
      .table-wrapper .el-table thead .cell {
        font-size: 0.9rem;
        color: #2d8ef0;
        text-align: center;
      }
      .table-wrapper .el-table tr {
        background-color: transparent !important;
      }
      .table-wrapper .el-table--enable-row-transition .el-table__body td,
      .el-table .cell {
        background-color: transparent;
        color: #e1e0f5;
        text-align: center;
        padding: 0.5rem 0.4rem;
      }
      // 谷歌隐藏滚动条
      .el-table--scrollable-y ::-webkit-scrollbar {
        display: none;
      }
      // 隐藏竖向滚动条
      // .el-table--scrollable-y .el-table__body-wrapper {
      //   overflow-y: scroll;
      // }

      /* -- 滚动条 start -- */
      // .el-table--scrollable ::-webkit-scrollbar {
      //   width: 10px;
      //   height: 10px;
      //   background-color: rgb(2,10,30);
      // }

      /* !*定义滚动条轨道 内阴影+圆角*! */
      // ::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgb(2,10,30);
      //   -moz-box-shadow: inset 0 0 6px rgb(2,10,30);
      //   -ms-box-shadow: inset 0 0 6px rgb(2,10,30);
      //   -o-box-shadow: inset 0 0 6px rgb(2,10,30);
      //   box-shadow: inset 0 0 6px rgb(2,10,30);
      //   border-radius: 10px;
      //   background-color: rgb(2,10,30);
      // }

      /*!*定义滑块 内阴影+圆角*!*/
      // ::-webkit-scrollbar-thumb {
      //   border-radius: 10px;
      //   -webkit-box-shadow: inset 0 0 2px rgb(75, 75, 75);
      //   -moz-box-shadow: inset 0 0 2px rgb(75, 75, 75);
      //   -ms-box-shadow: inset 0 0 2px rgb(75, 75, 75);
      //   -o-box-shadow: inset 0 0 2px rgb(75, 75, 75);
      //   box-shadow: inset 0 0 2px rgb(75, 75, 75);
      //   background-color: rgb(75, 75, 75);
      // }

      /* -- 滚动条 end -- */

      .el-table th,
      .el-table tr {
        background: transparent;
      }

      // 设置表格透明
      .table-wrapper-1 .el-table,
      .el-table__expanded-cell {
        margin-left: 2%;
        background-color: transparent;
        position: absolute;
        top: 15%;
      }
      .table-wrapper-1 .el-table thead .cell {
        font-size: 0.9rem;
        color: #2d8ef0;
        text-align: center;
      }
      .table-wrapper-1 .el-table tr {
        background-color: transparent !important;
      }
      .table-wrapper-1 .el-table--enable-row-transition .el-table__body td,
      .el-table .cell {
        background-color: transparent;
        color: #e1e0f5;
        text-align: center;
        padding: 0.15rem 0.4rem;
      }
      // 去掉头部横线
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 0px;
      }
      // 去掉斑马线横线
      .el-table__row > td {
        border-bottom: 1px solid rgba(251, 251, 251, 0.1);
      }
      .el-table::before {
        //去掉最下面的那一条线
        height: 0px;
      }
    }
    // 闪光点样式
    @keyframes fade {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }

    @-webkit-keyframes fade {
      from {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
    .dot {
      animation: fade 600ms infinite;
      -webkit-animation: fade 600ms infinite;
    }
  }
}
</style>
