<template>
  <div class="echart-comp"
       v-loading="loading">
    <!--折线图-->
    <div v-if="!this.datas.x || this.datas.x.length === 0"
         class="nodata">暂无数据</div>
    <Echart v-else width="100%"
            height="100%"
            :options="options"></Echart>
  </div>
</template>

<script>
import Echart from '@/components/Echart/index.vue'

export default {
  name: 'ledger-line-chart',
  components: {
    Echart
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: {},
      loading: false
    }
  },
  computed: {},
  watch: {
    datas: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    init() {
      this.loading = true
      if (this.datas) {
        var datas = this.datas
        var colors = ['#2ED8FF', '#FF8724', '#2D8EF0']
        this.options = {
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: {
            left: '2%',
            right: '8%',
            bottom: '6%',
            containLabel: true
          },
          // 右上角工具栏
          toolbox: false,
          legend: {
            data: ['出块时间', '账本数', '目标数'],
            textStyle: { color: '#e1e0f5' }
          },
          xAxis: [
            {
              type: 'category',
              name: '(区块高度)',
              nameTextStyle: {
                align: 'right',
                verticalAlign: 'top'
              },
              nameRotate: 60,
              nameLocation: 'end',
              axisLine: {
                lineStyle: {
                  color: colors[2] // X轴标题颜色
                }
              },
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {
                rotate: 60,
                // x轴字体颜色
                textStyle: {
                  color: '#e1e0f5'
                }
              },
              splitLine: {
                show: false
              },
              // 区块高度
              data: datas.x
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '(打包账本数/个)',
              // min: 0,
              // max: 15,
              minInterval: 5,
              position: 'right',
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2] // Y轴颜色
                }
              },
              axisLabel: {
                formatter: '{value}',
                // y轴字体颜色
                textStyle: {
                  color: '#e1e0f5'
                }
              },
              splitLine: {
                show: false
              }
            },
            {
              type: 'value',
              name: '(出块时间/ms)',
              // min: 2800,
              // max: 3100,
              position: 'left',
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2]
                }
              },
              axisLabel: {
                formatter: '{value}',
                // y轴字体颜色
                textStyle: {
                  color: '#e1e0f5'
                }
              },
              splitLine: {
                show: false
              }
            }
          ],
          // 数据项
          series: [
            {
              name: '账本数',
              type: 'bar',
              yAxisIndex: 0,
              itemStyle: {
                color: 'rgba(45,142,240,0.5)',
                borderWidth: 1,
                borderColor: colors[2]
              },
              data: datas.y1
            },
            {
              name: '目标数',
              type: 'bar',
              yAxisIndex: 0,
              itemStyle: {
                color: 'rgba(255,135,36,0.5)',
                borderWidth: 1,
                borderColor: colors[1]
              },
              data: datas.y2
            },
            {
              name: '出块时间',
              type: 'line',
              yAxisIndex: 1,
              data: datas.y3
            }
          ]
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.echart-comp {
  height: 100%;
  position: relative;
  & > .nodata {
    position: absolute;
    top: 35%;
    left: 40%;
    transform: translate3d(-50%,-50%);
    z-index: 2;
    color: #999;
  }
}
</style>
