<template>
  <div class="echart-comp"
       v-loading="loading">
    <!--折线图-->
    <div  v-if="!this.datas.x || this.datas.x.length === 0"
         class="nodata">暂无数据</div>
    <Echart v-else width="100%"
            height="100%"
            :options="options"></Echart>
  </div>
</template>

<script>
import Echart from '@/components/Echart/index.vue'

export default {
  name: 'block-line-chart',
  components: {
    Echart
  },
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: {},
      loading: false
    }
  },
  computed: {},
  watch: {
    datas: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    init() {
      this.loading = true
      if (this.datas) {
        var datas = this.datas
        var _maxY = Math.max.apply(null, datas.y)
        var maxY = Math.floor(_maxY / 1000) * 2000
        // console.log('最大值:', maxY)
        var colors = ['#2ED8FF', '#FF8724', '#2D8EF0']
        this.options = {
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          grid: {
            left: '2%',
            right: '8%',
            bottom: '6%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              name: '(时间/天)',
              data: datas.x,
              nameTextStyle: {
                align: 'right',
                verticalAlign: 'top'
              },
              nameRotate: 60,
              nameLocation: 'end',
              axisLine: {
                lineStyle: {
                  color: colors[2] // X轴标题颜色
                }
              },
              axisLabel: {
                rotate: 60,
                // x轴字体颜色
                textStyle: {
                  color: '#e1e0f5'
                }
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '(出块数量/个)',
              max: maxY,
              minInterval: 500,
              position: 'left',
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[2]
                }
              },
              axisLabel: {
                formatter: '{value}',
                // y轴字体颜色
                textStyle: {
                  color: '#e1e0f5'
                }
              },
              splitLine: {
                show: false
              }
            }
          ],
          series: [
            {
              name: '出块数量',
              type: 'bar',
              barWidth: '60%',
              data: datas.y,
              yAxisIndex: 0,
              itemStyle: {
                color: 'rgba(45,142,240,0.5)',
                borderWidth: 1,
                borderColor: colors[2]
              }
            }
          ]
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.echart-comp {
  height: 100%;
  position: relative;
  & > .nodata {
    position: absolute;
    top: 35%;
    left: 40%;
    transform: translate3d(-50%,-50%);
    z-index: 2;
    color: #999;
  }
}
</style>
