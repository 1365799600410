<template>
  <div class="echart-comp"
       v-loading="loading">
    <!--世界地图-->
    <div id="main"
         class="map-page"></div>
  </div>
</template>

<script>
import { template as lodashTemplate } from 'lodash'
import 'echarts/extension/bmap/bmap'
const tooltipRender = lodashTemplate(`
  <div style="width: <%- width %>; height: <%- height %>; font-size: 14px; color: #2ED8FF; background: url('<%- backgroundImage %>') no-repeat; background-size: 100% 100%;">
    <div style="font-size: 16px; letter-spacing: .2rem;text-align: center; font-weight: 600;height: 36px;line-height:36px">···&ensp;节点信息&ensp;···</div>
    <div style="display: flex; flex-direction: column;margin: .3rem 0;">
      <div style="padding: 4px;display: flex; flex-direction: row; justify-content: center;position: relative;left: 50%;transform: translateX(-47%);width: 220px;">
        <span>节点地址：</span>
        <div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 160px;"><%- Address %></div>
      </div>
      <div style="padding: 4px;display: flex; flex-direction: row; justify-content: center;position: relative;left: 50%;transform: translateX(-47%);width: 220px;">
        <span>区块高度：</span>
        <div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;width: 160px;"><%- Height %></div>
      </div>
      <div style="padding: 4px;display: flex; flex-direction: row;position: relative;width: 220px;left: 50%;transform: translateX(-47%);">
        <span>连&ensp;接&ensp;数：</span>
        <div><%- Peer %></div>
      </div>
      <div style="padding: 4px;display: flex; flex-direction: row;position: relative;width: 220px;left: 50%;transform: translateX(-47%);">
        <span>待处理账本：</span>
        <div><%- Pool.Pending %></div>
      </div>
      <div style="padding: 4px;display: flex; flex-direction: row;position: relative;width: 220px;left: 50%;transform: translateX(-47%);">
        <span>待打包账本：</span>
        <div><%- Pool.Completed %></div>
      </div>

    </div>
  </div>
`)
export default {
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    datas: {
      handler() {
        this.listNodeMap()
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      myChart: '',
      linesData: [],
      option: {},
      nodes: [],
      cityList: [],
      timer: null // 定时器
    }
  },
  mounted() {
    this.listNodeMap()
    this.init()
  },
  beforeDestroy() {
    if (this.timer) {
      window.clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 定时刷新数据
    refresh(data) {
      if (this.timer) {
        window.clearInterval(this.timer)
      }
      this.timer = window.setInterval(() => {
        this.cityList = []
        this.getNodeInfo(data)
      }, 15000)
    },
    // 获取地图节点
    async listNodeMap() {
      // 清空城市节点
      this.cityList = []
      // 清空连接线
      this.linesData = []
      let options = {
        peerName: this.datas.shard,
        keepLive: true
      }
      // 获取节点列表
      let { data } = await this.$api.home.getNodes(options)
      // 循环体外
      if (data && data.length !== 0) {
        this.nodes = data
        this.getNodeInfo(data)
        this.refresh(data)
        // 循环体外
      }
      this.init()
      // console.log('所有连接线：', this.deteleObject(this.linesData))
    },
    async getNodeInfo(data) {
      for (let item of data) {
        // 获取节点信息
        let option = {
          ip: item.networkIP,
          port: item.networkPort,
          json: '{"mode":"Chain","action":"info"}'
        }
        let { data } = await this.$api.home.nodeInfo(option)
        // console.log(JSON.parse(data.message))
        // 拿到链节点的ip与端口再比对拿到经纬度
        if (data) {
          // 当前节点地址
          let address = item.networkIP + ':' + item.networkPort
          // 显示地图节点
          let message = JSON.parse(data.message)
          this.locationBMap(
            item.longitude,
            item.latitude,
            address,
            message.Chain.Height,
            message.Peer.length,
            message.Pool
          )
          let Peer = message.Peer
          for (let list of Peer) {
            // 起点
            var start = []
            start[0] = item.longitude
            start[1] = item.latitude
            // console.log('起点：', start)
            // 终点
            var end = []
            // 连接线
            var line = []
            // 判断连接的节点
            for (let node of this.nodes) {
              if (
                JSON.parse(list).address === node.networkIP &&
                JSON.parse(list).port === node.networkPort
              ) {
                end[0] = node.longitude
                end[1] = node.latitude
              }
            }
            // console.log('终点：', end)
            if (end.length !== 0) {
              line[0] = start
              line[1] = end
              // console.log('连接线：', line)
              var obj = {}
              obj.coords = line
              // console.log('连接线对象：', obj.coords[1])
              this.linesData.push(obj)
            }
          }
        }
      }
    },
    // 删除相同节点
    deteleObject(obj) {
      var uniques = []
      var stringify = {}
      for (var i = 0; i < obj.length; i++) {
        var keys = Object.keys(obj[i])
        keys.sort(function (a, b) {
          return Number(a) - Number(b)
        })
        var str = ''
        for (var j = 0; j < keys.length; j++) {
          str += JSON.stringify(keys[j])
          str += JSON.stringify(obj[i][keys[j]])
        }
        if (!stringify.hasOwnProperty(str)) {
          uniques.push(obj[i])
          stringify[str] = true
        }
      }
      return uniques
    },
    // 百度地图获取城市名称
    async locationBMap(lon, lat, address, height, peer, Pool) {
      // eslint-disable-next-line no-undef
      var gc = new BMap.Geocoder()
      var _this = this
      // eslint-disable-next-line no-undef
      gc.getLocation(new BMap.Point(lon, lat), function (rs) {
        var addComp = rs.addressComponents
        var city = addComp.city // 获取城市
        var obj = {}
        obj.name = city.substring(0, city.length - 1)
        obj.value = [lon, lat, address, height, peer, Pool]
        _this.cityList.push(obj)
        // 删除相同节点
        // _this.cityList = _this.deteleObject(_this.cityList)
        // console.log('城市数组:', _this.cityList)
        if (_this.myChart) {
          // 更新配置
          // _this.myChart.setOption(_this.option)
          _this.init()
        }
      })
    },
    init() {
      var echarts = require('echarts')
      // if (this.myChart) {
      //   echarts.dispose(this.myChart)
      // }
      this.myChart = echarts.init(document.getElementById('main'))
      this.option = {
        title: {
          text: '',
          subtext: '',
          sublink: '',
          left: ''
        },
        tooltip: {
          padding: 0,
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          formatter: function (params) {
            // console.log('数据:', params.value)
            if (params.value) {
              return tooltipRender({
                width: '260px',
                height: '200px',
                backgroundImage: require('../img/toolTip.png'),
                Address: params.value[2],
                Height: params.value[3],
                Peer: params.value[4],
                Pool: params.value[5]
              })
            }
          },
          axisPointer: {
            type: 'line'
          }
        },
        bmap: {
          // center: [108, 34],
          // zoom: 5,
          roam: true, // 滚动
          mapStyle: {
            styleJson: [
              // 海洋
              {
                featureType: 'water',
                elementType: 'all',
                stylers: {
                  color: '#113549'
                }
              },
              // 陆地
              {
                featureType: 'land',
                elementType: 'all',
                stylers: {
                  color: '#091220'
                }
              },
              // 铁路
              {
                featureType: 'railway',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              // 高速公路
              {
                featureType: 'highway',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              // 国道
              {
                featureType: 'arterial',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'poi',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'green',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'subway',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'manmade',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'local',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'boundary',
                elementType: 'geometry.fill',
                stylers: {
                  color: '#666666'
                }
              },
              {
                featureType: 'boundary',
                elementType: 'geometry.stroke',
                stylers: {
                  color: '#f5dcad'
                }
              },
              {
                featureType: 'building',
                elementType: 'all',
                stylers: {
                  color: '#d1d1d1'
                }
              },
              {
                featureType: 'country',
                elementType: 'labels',
                stylers: {
                  visibility: 'on'
                }
              },
              {
                featureType: 'continent',
                elementType: 'labels.text.fill',
                stylers: {
                  color: '#9c3838ff'
                }
              },
              {
                featureType: 'continent',
                elementType: 'labels.text.stroke',
                stylers: {
                  color: '#fff'
                }
              },
              // 地名
              {
                featureType: 'label',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'city',
                elementType: 'all',
                stylers: {
                  visibility: 'off'
                }
              },
              {
                featureType: 'districtlabel',
                elementType: 'labels.text.stroke',
                stylers: {
                  color: '#ffffff00'
                }
              },
              {
                featureType: 'districtlabel',
                elementType: 'labels.text.fill',
                stylers: {
                  color: '#50e3c2ff'
                }
              }
            ]
          }
        },
        series: [
          {
            name: '节点信息',
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            data: this.cityList,
            symbolSize: 15,
            encode: {
              value: 2
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            label: {
              formatter: '{b}',
              position: 'right',
              show: true,
              color: '#fff',
              fontSize: 16
            },
            itemStyle: {
              color: '#eb6b1d',
              shadowBlur: 10
            },
            // labelLine: {
            //   show: true
            // },
            zlevel: 1
          },
          {
            name: '连接线',
            type: 'lines',
            coordinateSystem: 'bmap',
            zlevel: 1,
            effect: {
              show: true,
              constantSpeed: 20,
              symbol: 'triangle',
              symbolSize: 10,
              trailLength: 0,
              loop: true
              // color: '#3EEAFF'
            },
            lineStyle: {
              color: '#eb6b1d',
              type: 'solid',
              width: 2,
              curveness: 0.02
            },
            data: this.deteleObject(this.linesData)
          }
        ]
      }
      this.myChart.setOption(this.option)
    }
  }
}
</script>
<style lang="scss">
.echart-comp {
  width: 100%;
  height: 100%;
  position: relative;
  // background-color: rgb(49, 64, 82);
  .map-page {
    width: 100%;
    height: 100%;
  }
  .anchorBL {
    display: none;
    // 去除百度地图水印
  }
  & > .nodata {
    position: absolute;
    top: 50%;
    left: 40%;
    z-index: 2;
    color: #999;
  }
}
</style>
